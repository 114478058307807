import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ErrorService } from '../error/error.service';


@Injectable({
  providedIn: 'root'
})
export class UploadService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor
  (
    private httpClient:HttpClient,
    private errorService: ErrorService
  ) { }

  async uploadFile(files:FormData, folder:string, subFolder:string, id_user:number, id_dossier:number):Promise<Observable<{ID:number}[]>>
  {
    const url  = `${this.HOST}/upload`

    return this.httpClient.post<{ID:number}[]>(url, files, 
      {
        params:
        {
          folder:folder,
          subFolder:subFolder,
          id_user:id_user,
          id_dossier:id_dossier
        }
      })
  }

  async testUploadFile(files:FormData, folder:string, subFolder:string):Promise<Observable<{code:number, data:string}>>
  {
    const url  = `${this.HOST}/test-upload`

    return this.httpClient.post<{code:number, data:string}>(url, files, 
      {
        params:
        {
          folder:folder,
          subFolder:subFolder
        }
      })
      .pipe(
        retry({
          count: 3,
          delay: (error, retryCount) => timer(retryCount * 1000), // Exponential backoff: 1s, 2s, 3s
        }),
        catchError(this.errorService.handleError)
      )
  }
}
