import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService 
{
  constructor() { }

  handleError(error: Error) 
  {
    console.error('Error occurred:', error)
    return throwError(() => new Error('Something went wrong, please try again later.'))
  }
}
