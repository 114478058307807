<app-alert></app-alert>

<img src="/assets/icons/close.png" alt="" class="white-filter rotate-left cursor float-end m-2 img-close" (click)="displaySidebar()" *ngIf="switchToTabletMode()">

<nav class="nav nav-main h-100">
    <div class="w-100 position-relative buttons blue">
        <div id="cta-menu-open" class="cursor bg-blue">></div>
        <div id="cta-menu-close" class="cursor bg-blue"><</div>
    </div>

    <ul class="accordion accordion-flush">
        <li class="accordion-item">
            <div class="nav-menu-item-group cursor pe-4 accordion-item accordion-button dashboard collapsed" data-bs-toggle="collapse" data-bs-target="#collapseDashboard" (click)="setActive($event)">
                <a class="nav-menu-item" routerLink="/" routerLinkActive="router-link-active">Tableau de bord</a>
            </div>
            <ul class="nav-menu-sub-item-group bg-blue-2 accordion-collapse collapse" id="collapseDashboard">
                <li class="nav-menu-sub-item">
                    <a class="white cursor" (click)="goTo('dossiers-en-cours')">Dossiers en cours</a>
                    &nbsp; 
                    <span class="badge rounded-pill text-center" [ngClass]="nb_en_cours > 0 ? 'bg-orange white': 'bg-white blue'">{{ nb_en_cours }}</span>
                </li>
                <li class="nav-menu-sub-item">
                    <a class="white cursor" (click)="goTo('messages-non-lus')">Messages non lus</a>
                    &nbsp; 
                    <span class="badge rounded-pill" [ngClass]="nb_message_non_lus > 0 ? 'bg-orange white': 'bg-white blue'">{{ nb_message_non_lus }}</span>
                </li>
                <li class="nav-menu-sub-item">
                    <a class="white cursor smallerText lh-sm" (click)="goTo('documents-non-consultes')">Documents non consultés</a>
                    &nbsp; 
                    <span class="badge rounded-pill" [ngClass]="nb_document_non_lus > 0 ? 'bg-orange white': 'bg-white blue'">{{ nb_document_non_lus }}</span>
                </li>
                <li class="nav-menu-sub-item">
                    <a class="white cursor" (click)="goTo('dossiers-clos')">Dossiers clos</a>
                    &nbsp; 
                    <span class="badge rounded-pill" [ngClass]="nb_clos > 0 ? 'bg-orange white': 'bg-white blue'">{{ nb_clos }}</span>
                </li>
            </ul>
        </li>
        <li class="accordion-item">
            <div class="nav-menu-item-group cursor pe-4 accordion-item accordion-button dossiers collapsed" data-bs-toggle="collapse" data-bs-target="#collapseDossier" (click)="setActive($event)">
                <a class="nav-menu-item" routerLink="/dossiers" routerLinkActive="router-link-active">Dossiers</a>
            </div>
            <ul class="nav-menu-sub-item-group bg-blue-2 accordion-collapse collapse" id="collapseDossier">
                @if ( isDetailsDossierVisible ) 
                {
                    <li class="nav-menu-sub-item white bg-blue-dark-1">
                        <a class="dossiers-details">Dossier {{ ref }}</a>
                    </li>
                }

                <li class="nav-menu-sub-item" [ngClass]="isDetailsDossierVisible ? 'ps-5':''">
                    <a routerLink="/dossiers/documents" routerLinkActive="router-link-active">Documents</a>
                    <span class="badge rounded-pill" [ngClass]="nb_document && nb_document > 0 ? 'bg-orange white' : 'bg-white blue'">{{ nb_document }}</span>
                </li>
                <li class="nav-menu-sub-item" [ngClass]="isDetailsDossierVisible ? 'ps-5':''">
                    <a routerLink="/dossiers/messages" routerLinkActive="router-link-active" >Messages</a>
                    <span class="badge rounded-pill" [ngClass]="nb_message && nb_message > 0 ? 'bg-orange white' : 'bg-white blue'">{{ nb_message }}</span>
                </li>

                @if ( isDetailsDossierVisible ) 
                {
                    <li class="nav-menu-sub-item ps-5"><a class="cursor white" (click)="goToSaisine(ref)">Voir la saisine</a></li>
                }
            </ul>
        </li>
        <li class="accordion-item">
            <div class="nav-menu-item-group cursor">
                <a class="white infobulle" (click)="newSaisine(idUser)">
                    Nouvelle saisine
                    <span class="indication grey">
                        <span class="icon">
                            <span class="toolTipAction">
                                <img class="picto mx-2" src="/assets/img/picto-question.png" alt="question" height="15">
                                <span class="small bg-grey-light blue-dark-1 p-2 toolTipContent">
                                    <img src="/assets/icons/picto-info.png" alt="" height="15"> La nouvelle saisine doit concerner un nouveau litige
                                </span>
                            </span>
                        </span>
                    </span>
                </a>
            </div>
        </li>
    </ul>
</nav>
<div class="guide cursor">
    <img src="assets/icons/manuel.png" alt="Guide d'utilisation de l'espace abonné">
    <a class="" target="_blank" href="{{lien_guide}}">Guide d'utilisation de l'espace abonné</a>
</div>
