import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ProfilService } from '../../services/profil/profil.service';
import { ListeActionsService } from '../../services/liste-actions/liste-actions.service';
import { ConnexionService } from '../../services/connexion/connexion.service';
import { LastConnexion } from '../../interfaces/ListeActions';
import { SharedService } from '../../services/shared/shared.service';

@Component({
    selector: 'app-header',
    standalone:  true,
    templateUrl: 'header.component.html',
    styleUrl: 'header.component.css',
    imports: [RouterLink]
})

export class HeaderComponent
{
    nom_espace:string = "ESPACE "
    civilite!:string
    nom!:string
    prenom!:string
    idUser!:number
    profil:string
    last_connexion!:string
    isDataReady:boolean = false

    constructor
    (
        private profilService: ProfilService,
        private listeActionsService: ListeActionsService,
        private connexionService: ConnexionService,
        private sharedService: SharedService
    )
    {
        const id_user = this.sharedService.getIdUser()
        this.idUser = id_user

        const profil = Number(sessionStorage.getItem("profil"))
        if(profil == 3) this.profil = "ABONNE"
        else if(profil == 5) this.profil = "ABONNE (REPRESENTANT)"
        else this.profil = "ABONNE / REPRESENTANT"
    }

    ngOnInit()
    {
        this.getDataHeader()
    }

    getDataHeader()
    {
        this.profilService.getProfil(this.idUser).subscribe(data => {
            if(data)
            {
                this.civilite = data[0]?.civilite
                this.nom = data[0]?.nom
                this.prenom = data[0]?.prenom
                this.isDataReady = true
            }
        })

        this.listeActionsService.getLastConnexion(this.idUser).subscribe((data:LastConnexion[]) => {
            if(data)
            {
                this.last_connexion = data[0]?.last_connexion.toString()
            }
        })
    }

    deconnexion()
    {
        this.connexionService.logout()
    }
}