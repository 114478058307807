import { Component } from '@angular/core';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { SharedService } from '../../../services/shared/shared.service';
import { SharedModule } from '../../../modules/shared/shared.module';
import { Saisine } from '../../../interfaces/Saisine';
import { FormControl, FormGroup } from '@angular/forms';
import { zipValidator } from '../../../helpers/custom-validators/zip-code-validator';
import { SaisineService } from '../../../services/saisine/saisine.service';

@Component({
  selector: 'app-saisine',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './saisine.component.html',
  styleUrl: './saisine.component.css'
})
export class SaisineComponent 
{
  title:string = "Saisine"
  saisine_data!:Saisine[]
  form!:FormGroup
  isEditAdresse:boolean = false
  isEditCP:boolean = false
  isEditCommune:boolean = false
  isEditPays:boolean = false
  id_pays:number = 0
  liste_pays:{ID_PAYS:number, NOM_PAYS:string}[] = []
  explication_fuite!:string

  // Type document / contenu
  reclamation_service:boolean = false
  factures_eau_assainissement:boolean = false
  reponse_service:boolean = false
  facture_reparation_fuite:boolean = false
  autres_documents:boolean = false
  justificatif_representant:boolean = false

  type_contenu = 
  [
    "copie reclamation", // input 1 de l'étape 6 de la saisine
    "copie factures",  // input 2 de l'étape 6 de la saisine
    "reponse service", // input 3 de l'étape 6 de la saisine
    "indication fuite", // input 4 de l'étape 6 de la saisine
    "autres documents", // input 5 de l'étape 6 de la saisine
    "justificatif representant" // input 6 de l'étape 6 de la saisine
  ]

  constructor
  (
    private navigationService: NavigationService,
    private sharedService: SharedService,
    private saisineService: SaisineService
  )
  {
    this.form = new FormGroup({
      adresse: new FormControl(""),
      cp: new FormControl(""),
      commune: new FormControl(""),
      id_pays: new FormControl(0),
      pays: new FormControl(""),
      id_dossier: new FormControl("")
    })
  }

  ngOnInit()
  {
    this.getPays()
    this.getData()

    this.form.get("id_pays")?.valueChanges.subscribe((id_pays:number) => {
      id_pays == 1 ? this.addCPValidator() : this.clearCPValidator()
      this.form.get("pays")?.setValue(this.liste_pays.find(pays => pays.ID_PAYS == id_pays)?.NOM_PAYS)
    })
  }


  goToHome()
  {
    this.navigationService.navigateByURL("", [null])
  }

  getPays()
  {
    this.sharedService.getPaysList().subscribe(liste_pays => {
      this.liste_pays = liste_pays
    })
  }

  getData()
  {
    const ref = this.sharedService.getParam("ref")?.toString() || ""
    this.saisineService.getSaisine(ref).subscribe(data => {
      this.saisine_data = data.dossier
      const documents = data.documents.map((document:{contenu:string}) => document.contenu)
      
      const adresse = this.saisine_data[0].adresse_correspondance
      const cp = this.saisine_data[0].cp_correspondance
      const commune = this.saisine_data[0].ville_correspondance
      const id_dossier = this.saisine_data[0].id_dossier
      this.id_pays = this.saisine_data[0].id_pays_correspondance
      this.explication_fuite = this.saisine_data[0].explication_fuite

      this.reclamation_service = documents?.includes("copie reclamation") ? true : false
      this.factures_eau_assainissement = documents?.includes("copie factures") ? true : false
      this.reponse_service = documents?.includes("reponse service") ? true : false
      this.facture_reparation_fuite = documents?.includes("indication fuite") ? true : false
      this.autres_documents = documents?.includes("autres documents") ? true : false
      this.justificatif_representant = documents?.includes("justificatif representant") ? true : false

      this.form.get("adresse")?.setValue(adresse)
      this.form.get("cp")?.setValue(cp)
      this.form.get("commune")?.setValue(commune)
      this.form.get("id_dossier")?.setValue(id_dossier)
      this.form.get("id_pays")?.setValue(this.id_pays)
    })
  }

  isUpdateButtonVisible()
  {
    if( this.isEditAdresse || this.isEditCP || this.isEditCommune || this.isEditPays )
    {
      return true
    }
    else 
    {
      return false 
    }
  }

  editAdresse()
  {
    return this.isEditAdresse = true
  }

  editCP()
  {
    return this.isEditCP = true
  }

  editCommune()
  {
    return this.isEditCommune = true
  }

  editPays()
  {
    this.setValuePays(this.id_pays)
    return this.isEditPays = true
  }

  setValuePays(id_pays:number)
  {
    if(id_pays != 0 && id_pays) 
    {
      this.form.get("id_pays")?.setValue(id_pays)
      this.updateValuePays()
    }
  }

  hideAllInput()
  {
    this.isEditAdresse = false
    this.isEditCP = false
    this.isEditCommune = false
    this.isEditPays = false
    return
  }

  addCPValidator()
  {
    this.form.get("cp")?.setValidators([zipValidator()])
    this.updateValueCPValidator()
  }

  clearCPValidator()
  {
    this.form.get("cp")?.clearValidators()
    this.updateValueCPValidator()
  }

  updateValueCPValidator()
  {
    this.form.get("cp")?.updateValueAndValidity()
  }

  updateValuePays()
  {
    this.form.get("id_pays")?.updateValueAndValidity()
    this.form.get("pays")?.updateValueAndValidity()
  }

  showAllInput()
  {
    this.isEditAdresse = true
    this.isEditCP = true
    this.isEditCommune = true
    this.isEditPays = true
    return
  }

  isInvalid(controlName: string)
  {
    const control = this.form.get(controlName)
    return control?.invalid && (control.dirty || control.touched)
  }

  saveNewAdresseCorrespondance()
  {
    const id_pays = parseInt(this.form.get("id_pays")?.value)

    if (this.form.invalid) 
    {
      if (id_pays == 1) this.isEditCP = true
      this.form.get("cp")?.markAsDirty()
      this.form.get("cp")?.markAsTouched()
      return
    }
    else
    {
      const form = this.form.value

      this.saisineService.updateAdresse(form).subscribe()

      this.updateValuePays()

      this.hideAllInput()
    }
  }
}
