<div class="main-header">
    <div class="ariane">
        <img class="picto cursor" src="/assets/img/picto-home.png" alt="retourner à la page d'accueil" (click)="goToHome()">
        <div class="item small cursor"></div>
    </div>
    <h1 class="grey">{{ title }}</h1>
</div>

@if(saisine_data)
{
    <form class="saisine bg-white d-flex flex-column" [formGroup]="form">
        <section class="col-5 white bold px-3 py-2 m-3 header-saisine bg-blue-dark-2">
            <div>Dossier n° {{saisine_data[0].ref}} {{saisine_data[0].abonne}}</div>
            <div>Date d'enregistrement de la saisine : {{saisine_data[0].date | date :'dd/MM/yyyy'}}</div>
            <div>Origine saisine : {{saisine_data[0].type_saisine }}</div>
        </section>
    
        <section class="saisine-content d-flex blue-dark-2">
            <section class="col-6 saisine-left p-3">
                <div class="abonne_representant">

                    <h5 class="bold">Vous et/ou votre représentant</h5>
                    <div *ngIf="saisine_data[0].representant">
                        <h6 class="bold">Vous êtes le représentant de l'abonné</h6>
                        <div class="saisine-content-text pt-1 row">
                            <span class="col-2 py-1">Nom : </span> 
                            <span class="col-3 py-1">{{saisine_data[0].nom_representant}}</span> 

                            <span class="col-2 py-1" *ngIf="saisine_data[0].entreprise_representant">Société : </span>
                            <span class="col-3 py-1" *ngIf="saisine_data[0].entreprise_representant">{{saisine_data[0].entreprise_representant}}</span>
                        </div>
                        <div class="saisine-content-text pt-1 row">
                            <span class="col-2 py-1">Prénom : </span> 
                            <span class="col-3 py-1">{{saisine_data[0].prenom_representant}}</span> 

                            <span class="col-4 py-1" *ngIf="saisine_data[0].profil_representant">Votre lien avec l'abonné : </span>
                            <span class="col-3 py-1" *ngIf="saisine_data[0].profil_representant">{{saisine_data[0].profil_representant}}</span>
                        </div>
                        <div class="saisine-content-text pt-1 row">
                            <span class="col-2 py-1">Email : </span> 
                            <span class="col-3 py-1">{{saisine_data[0].email_representant}}</span> 

                            <span class="col-4 py-1" *ngIf="saisine_data[0].ref_externe">Votre référence dossier : </span>
                            <span class="col-3 py-1" *ngIf="saisine_data[0].ref_externe">{{saisine_data[0].ref_externe}}</span>
                        </div>
                        <br>
                    </div>
    
                    <h6 class="bold">L'abonné(e)</h6>
                    <div class="saisine-content-text pt-1 row">
                        <span class="col-2 py-1">Nom : </span> 
                        <span class="col-3 py-1">{{saisine_data[0].nom}}</span> 

                        <span class="col-2 py-1" *ngIf="saisine_data[0].entreprise">Société : </span>
                        <span class="col-3 py-1" *ngIf="saisine_data[0].entreprise">{{saisine_data[0].entreprise}}</span>
                    </div>
                    <div class="saisine-content-text pt-1 row">
                        <span class="col-2 py-1">Prénom : </span> 
                        <span class="col-3 py-1">{{saisine_data[0].prenom}}</span> 
                    </div>
                    <div class="saisine-content-text pt-1 row">
                        <span class="col-2 py-1" *ngIf="saisine_data[0].email">Email</span>
                        <span class="col-3 py-1" *ngIf="saisine_data[0].email">{{saisine_data[0].email}}</span>
                    </div>
                </div>
    
                <hr class="saisine-separator">
    
                <div class="litige">
                    <h5 class="blue-dark-2 bold ">Contrat</h5>
                    <h6 class="blue-dark-2 bold">Type de contrat</h6>
                    <div class="saisine-content-text">{{saisine_data[0].type_contrat}}</div>
    
                    <div *ngIf="saisine_data[0].info_eau_potable">
                        <br>
        
                        <h6 class="blue-dark-2 bold">Information sur la facturation en eau potable</h6>
                        <div class="saisine-content-text fst-italic">{{saisine_data[0].info_eau_potable}}</div>
                    </div>

                    <div *ngIf="saisine_data[0].info_assainissement_collectif">
                        <br>
        
                        <h6 class="blue-dark-2 bold">Information sur la facturation d'assainissement collectif</h6>
                        <div class="saisine-content-text fst-italic">{{saisine_data[0].info_assainissement_collectif}}</div>
                    </div>
                </div>

                <hr class="saisine-separator">
    
                <div class="litige">
                    <h5 class="blue-dark-2 bold ">Le litige</h5>
                    <h6 class="blue-dark-2 bold">Exposé du litige</h6>
                    <div class="saisine-content-text fst-italic" >{{saisine_data[0].litige}}</div>
    
                    <br>
    
                    <h6 class="blue-dark-2 bold">Ce que vous attendez du service d'eau et/ou d'assainissement</h6>
                    <div class="saisine-content-text fst-italic">{{saisine_data[0].attentes}}</div>

                    <br>
                    
                    <h6 class="blue-dark-2 bold">Adresse du litige</h6>
                    <div class="saisine-content-text">
                        {{saisine_data[0].adresse}}
                        {{saisine_data[0].cp}}
                        {{saisine_data[0].ville}}
                    </div>
                </div>
    
                <hr class="saisine-separator">
    
                <div class="adresse_correspondance">
                    <h5 class="blue-dark-2 bold">Adresse de correspondance</h5>
                    <div class="saisine-content-text d-none">Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta est tempora dolorem necessitatibus magni repellendus adipisci assumenda rem enim, cumque recusandae consequuntur numquam ut odio commodi rerum vitae mollitia? Necessitatibus.</div>
    
                    <div class="mt-2">
                        <div class="row">
                            <div class="col-auto">
                                <label class="col-form-label">Adresse :</label>
                            </div>
                            
                            @if( isEditAdresse )
                            {
                                <div class="col-auto">
                                    <input class="form-control mt-1 mb-2" type="text" formControlName="adresse">
                                </div>
                            }
                            @else
                            {
                                <div class="col-auto d-flex align-self-center cursor saisine-content-text" (click)="editAdresse()">
                                    <span>{{ form.get('adresse')?.value.trim().length != 0 ? form.get('adresse')?.value : 'Non renseigné' }}</span>
                                </div>
                            }
                        </div>

 
                        <div class="row">
                            <div class="col-auto">
                                <label class="col-form-label">Code postal :</label>
                            </div>
                            
                            @if( isEditCP )
                            {
                                <div class="col-auto">
                                    <input class="form-control mt-1 mb-2" type="text" formControlName="cp" maxlength="30">
                                </div>

                                <em *ngIf="isInvalid('cp') && form.get('cp')?.errors?.['invalidPostalCode']">
                                    <p class="h5 text-danger font-weight-bold">* Code postal invalide</p>
                                </em>
                            }
                            @else
                            {
                                <div class="col-auto d-flex align-self-center cursor saisine-content-text" (click)="editCP()">
                                    <span>{{ form.get('cp')?.value.trim().length != 0 ? form.get('cp')?.value : 'Non renseigné' }}</span>
                                </div>
                            }
                        </div>
                        

                        <div class="row">
                            <div class="col-auto">
                                <label class="col-form-label">Commune :</label>
                            </div>
                            
                            @if( isEditCommune )
                            {
                                <div class="col-auto">
                                    <input class="form-control mt-1 mb-2" type="text" formControlName="commune" maxlength="100">
                                </div>
                            }
                            @else
                            {
                                <div class="col-auto d-flex align-self-center cursor saisine-content-text" (click)="editCommune()">
                                    <span class="wrap">{{ form.get('commune')?.value.trim().length != 0 ? form.get('commune')?.value : 'Non renseigné' }}</span>
                                </div>
                            }
                        </div>

                        <div class="row" *ngIf="form.get('id_pays')?.value == 1">
                            <div class="col-auto">
                                <label class="col-form-label">Pays :</label>
                            </div>
                        

                            @if( isEditPays )
                            {
                                <div class="col-auto">
                                    <select class="form-control cursor blue-dark-2 border border-primary" formControlName="id_pays" id="pays_select">
                                        <option class="blue-dark-2" *ngFor="let pays of liste_pays" [value]="pays.ID_PAYS" [selected]="pays.ID_PAYS == id_pays">{{ pays.NOM_PAYS }}</option>
                                    </select>
                                </div>
                            }
                            @else
                            {
                                <div class="col-auto d-flex align-self-center cursor saisine-content-text" (click)="editPays()">
                                    <span>{{ form.get('pays')?.value.trim().length != 0 ? form.get('pays')?.value : 'Non renseigné' }}</span>
                                </div>
                            }
                        </div>
    
                        @if( isUpdateButtonVisible() )
                        {
                            <button type="button" class="white bg-blue px-3 py-2 mt-2 ms-2 no-border float-start" (click)="saveNewAdresseCorrespondance()">Enregistrer</button>                        
                            <button type="button" class="white bg-grey px-3 py-2 mt-2 ms-2 no-border float-start" (click)="hideAllInput()">Annuler</button>                        
                        }
                        @else
                        {
                            <button type="button" class="white bg-blue px-3 py-2 mt-2 ms-2 no-border float-start" (click)="showAllInput()">Modifier</button>  
                        }
                    </div>
                </div>
            </section>

            <section class="col-6 saisine-right p-3">
                <div class="documents_fournis">
                    <h5 class="blue-dark-2 bold">Les documents que vous avez fournis au moment de la saisine</h5>
                    <div class="row" [ngClass]="factures_eau_assainissement ? 'bold': ''">
                        <p class="blue-dark-2 col-11">Factures d'eau et/ou d'assainissement</p> 
                        <span class="col-1">
                            {{ factures_eau_assainissement ? "Oui" : "Non"}}
                        </span>
                    </div>
    
                    <div class="row" [ngClass]="reclamation_service ? 'bold': ''">
                        <p class="blue-dark-2 col-11">Réclamation écrites adressées aux services d'eau et/ou assainissement</p>
                        <span class="col-1">
                            {{ reclamation_service ? "Oui" : "Non"}}
                        </span>
                    </div>
                    
                    <div class="row" [ngClass]="reponse_service ? 'bold': ''">
                        <div class="saisine-content-text col-11">Copie des réponses des services</div>
                        <span class="col-1">
                            {{ reponse_service ? "Oui" : "Non"}}
                        </span>
                    </div>
    
                    
                    <div *ngIf="explication_fuite">
                        <br>
                        <h6 class="blue-dark-2 bold col-11">Vous avez indiqué une fuite à l'emplacement suivant :</h6>
                        <div class="saisine-content-text fst-italic">{{explication_fuite}}</div>
                    </div>
                    
                    <br>
    
                    <div class="row" [ngClass]="facture_reparation_fuite ? 'bold': ''">
                        <p class="blue-dark-2 col-11">Copie de la facture de réparation de la fuite</p>
                        <span class="col-1">
                            {{ facture_reparation_fuite ? "Oui" : "Non"}}
                        </span>
                    </div>
                    
                    <div class="row" [ngClass]="autres_documents ? 'bold': ''">
                        <p class="saisine-content-text col-11">Vous avez fourni des documents complémentaires</p>
                        <span class="col-1">
                            {{ autres_documents ? "Oui" : "Non"}}
                        </span>
                    </div>

                    <div class="row" [ngClass]="justificatif_representant ? 'bold': ''">
                        <p class="saisine-content-text col-11">Justificatif du représentant</p>
                        <span class="col-1">
                            {{ justificatif_representant ? "Oui" : "Non"}}
                        </span>
                    </div>
                </div>
    
                <hr class="saisine-separator">
    
                <div class="recevabilite">
                    <h5 class="blue-dark-2 bold">Recevabilité</h5>
                    
                    <!--* Les conditions de recavabilité correspondent ce qui est présent dans la nouvelle saisine *-->

                    <div class="row">
                        <h6 class="blue-dark-2 bold col-11">Le litige est relatif à l'exécution du service public de l'eau et/ou de l'assainissement</h6>
                        <span class="col-1" [ngClass]="true ? 'bold': ''">Oui</span>
                    </div>
                    
                    <br>
    
                    <div class="row">
                        <h6 class="blue-dark-2 bold p-2 col-11">Vous avez lu les cas d'exclusion et confirmé que le litige n'est dans aucun des cas présentés</h6>
                        <span class="col-1" [ngClass]="true ? 'bold': ''">Oui</span>
                    </div>
    
                    <br>
    
                    <div class="row">
                        <h6 class="blue-dark-2 bold col-11">Vous êtes en litige avec votre service d'eau et/ou d'assainissement auprès duquel vous (ou votre abonné si vous êtes le représentant) êtes directement abonné</h6>
                        <span class="col-1" [ngClass]="true ? 'bold': ''">Oui</span>
                    </div>
    
                    <br>
    
                    <div class="row">
                        <h6 class="blue-dark-2 bold col-11">Une réclamation a été adressée au(x) service(s) moins d'un an avant la date de saisine</h6>
                        <span class="col-1" [ngClass]="true ? 'bold': ''">Oui</span>
                    </div>
    
                    <br>
    
                    <div class="row">
                        <h6 class="blue-dark-2 bold col-11">Aucune réponse n'a été reçue ou bien celle-ci n'est pas satisfaisante</h6>
                        <span class="col-1" [ngClass]="true ? 'bold': ''">Oui</span>
                    </div>
    
                    <br>
    
                    <div class="row">
                        <h6 class="blue-dark-2 bold col-11">Il n'y a aucun recours à la justice ou à un autre médiateur pour le litige concerné</h6>
                        <span class="col-1" [ngClass]="true ? 'bold': ''">Oui</span>
                    </div>
                </div>
            </section>
        </section>
    </form>
}
