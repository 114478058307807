import { Component, HostListener } from '@angular/core';
import { SharedModule } from '../modules/shared/shared.module';
import { ReturnTopButtonComponent } from "../common/return-top-button/return-top-button.component";
import { SharedService } from '../services/shared/shared.service';
import { MenuBurgerComponent } from "../common/menu-burger/menu-burger.component";
import { BackdropComponent } from "../common/backdrop/backdrop.component";
import { DialogEmail } from '../dialog/dialogEmail.component';
import { Router } from '@angular/router';
import { ChangePassword } from '../pages/changePassword.component';
import { SuiteSaisine } from '../pages/suite-saisine.component';
import { TestFeaturesService } from '../services/test-features/test-features.service';

@Component({
    selector: 'app-container',
    standalone: true,
    templateUrl: './app-container.component.html',
    styleUrl: './app-container.component.css',
    imports: [SharedModule, ReturnTopButtonComponent, MenuBurgerComponent, BackdropComponent]
})
export class AppContainerComponent 
{
    constructor
    (
        private sharedService: SharedService,
        private router: Router,
        // private tesFeaturesService: TestFeaturesService
    ) 
    {
        this.checkIfLoginIsNotEmail()
        this.checkIfUserHasDossier()
        this.checkIfDefaultPassword()

        // TEST //
        // this.tesFeaturesService.testUpload()
    }

    checkIfLoginIsNotEmail()
    {
        const sessionStorage_loginIsNotAnEmail = sessionStorage.getItem("loginIsNotAnEmail")
        if( sessionStorage_loginIsNotAnEmail )
        {
            const loginIsNotAnEmail:boolean = JSON.parse(sessionStorage_loginIsNotAnEmail)
            
            if( loginIsNotAnEmail == true )
            {
                this.sharedService.openDialog(DialogEmail)
            }
            else
            {
                sessionStorage.removeItem("loginIsNotAnEmail")
            }
        }
    }

    checkIfUserHasDossier()
    {
        const id_user = sessionStorage.getItem("id_user")?.toString() 

        if(id_user?.trim())
        {
            this.sharedService.getUserDossier(id_user).subscribe(userHasDossier => {
                if(!userHasDossier)
                {
                    this.needToContinueSaisine()
                }
            })
        }
    }

    checkIfDefaultPassword()
    {
        const change_password = JSON.parse((sessionStorage.getItem("default_password") as string)) || false

        if(change_password && this.router.url !== "profil") 
        {
            this.needToChangePassword()
        }
    }

    needToContinueSaisine()
    {
        this.sharedService.openDialog(SuiteSaisine)
    }

    needToChangePassword()
    {
        this.sharedService.openDialog(ChangePassword)
    }


    @HostListener('window:resize', ['$event'])
    onWindowResize() 
    {
        if(window.innerWidth >= 1400) 
        {
            (document.getElementById("sidebar") as HTMLElement).style.display = 'initial'
        }
        if(window.innerWidth < 1400) 
        {
            (document.getElementById("sidebar") as HTMLElement).style.display = 'none'
        }
    }

    switchToTabletMode()
    {
      return this.sharedService.switchToTabletMode()
    }
}
